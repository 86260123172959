import React from 'react';
import _ from 'lodash';
import moment from 'moment-strftime';

import {htmlToReact, Link, safePrefix} from '../utils';

export default class SectionJobOffers extends React.Component {
    render() {
      
        return _.get(this.props, 'section.published') ? (
            <section id={_.get(this.props, 'section.section_id')} className={'block posts-block outer'} style={{backgroundColor: _.get(this.props, 'section.bg')}}>
              <div className="block-header inner-small">
                {_.get(this.props, 'section.title') && 
                <h1 className="block-title">{_.get(this.props, 'section.title')}</h1>
                }
                {_.get(this.props, 'section.subtitle') && 
                <p className="block-subtitle">
                  {htmlToReact(_.get(this.props, 'section.subtitle'))}
                </p>
                }
              </div>
              <div className="inner">
                <div className="post-feed">
                  {_.map(this.props.section.jobOffers, (offer, index) => (
                  <article key={index} className="post post-card">
                    <div className="post-card-inside">
                      <div className="post-card-content">
                        <header className="post-header">
                          <h5 className="post-title">{offer.title}</h5>
                        </header>
                        <div className="post-excerpt">
                          <p>{offer.description}</p>
                        </div>
                        <footer className="post-meta">
                          <Link to={offer.url} style={{borderRadius: '28px', backgroundColor: 'rgb(218, 54, 84)', color: 'rgb(255, 255, 255)', fontSize: '0.9rem',padding: '0.8rem 1.5rem', textDecoration: 'none', textAlign: 'center', border: '0px', cursor: 'pointer', position: 'relative', zIndex: 1}}>Apply</Link>
                        </footer>
                      </div>
                    </div>
                  </article>
                  ))}
                </div>
              </div>
            </section>
        ):'';
    }
}
