import React from 'react';
import _ from 'lodash';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop'
import {htmlToReact, Link, safePrefix} from '../utils';

export default class SectionCta extends React.Component {
    render() {
        const styles = {
          color: _.get(this.props, 'section.textColor'),
          fontSize: _.get(this.props, 'section.fontSize')
        }
        
        const phraseObj = _.get(this.props, 'section.typistLoop.phrases')
        const phrases = phraseObj !== undefined ? phraseObj.map(v => v.phrase):[]

        return _.get(this.props, 'section.published') ? (
            <section id={_.get(this.props, 'section.section_id')} className="block cta-block bg-accent outer cta-section" style={{backgroundColor: _.get(this.props, 'section.bgColor')}}>
              <div className="inner-large">
                <div className="grid">
                  <div className="cell block-content">
                    {_.get(this.props, 'section.title') && 
                    <h2 className="block-title" style={styles}>{_.get(this.props, 'section.title')}</h2>
                    }
                    {_.get(this.props, 'section.subtitle') && 
                    <div className="block-subtitle" style={{...styles, clear: 'both'}}>
                      <span style={{float: 'left', paddingRight:'7px'}}>{htmlToReact(_.get(this.props, 'section.subtitle')) } </span>
                      {phrases.length > 0 && (
                        <TypistLoop interval={1000}>
                          {phrases.map((text, index)=> <Typist key={index} cursor={{
                            show: false,
                            blink: true,
                            element: '|',
                            hideWhenDone: false,
                            hideWhenDoneDelay: 1000,
                          }} startDelay={200}><span style={{fontSize: _.get(this.props, 'section.fontSize'), float:'left', fontWeight: 'bold', color: _.get(this.props, 'section.typistLoop.textColor')}}>{text}</span></Typist>)}
                        </TypistLoop>
                      )}
                    </div>
                    }
                  </div>
                  {_.get(this.props, 'section.actions') && 
                  <div className="cell block-buttons">
                    {_.map(_.get(this.props, 'section.actions'), (action, action_idx) => (
                    <Link key={action_idx} to={safePrefix(_.get(action, 'url'))} className="button white large">{_.get(action, 'label')}</Link>
                    ))}
                  </div>
                  }
                </div>
              </div>
            </section>
        ):'';
    }
}
