import React from 'react';
import _ from 'lodash';
import moment from 'moment-strftime';

import {htmlToReact, Link, safePrefix} from '../utils';

export default class SectionOptions extends React.Component {
    render() {
      
        return _.get(this.props, 'section.published') ? (
            <section id={_.get(this.props, 'section.section_id')} className={'block posts-block outer'} style={{backgroundColor: _.get(this.props, 'section.bgColor')}}>
              <div className="block-header inner-small">
                {_.get(this.props, 'section.title') &&
                <h2 className="block-title">{_.get(this.props, 'section.title')}</h2>
                }
              </div>
              <div className="inner">
                <div className="option-section">
                  {_.map(this.props.section.options, (option, index) => (
                  <article key={index} className="post option-card">
                    <div className="option-card-inside" style={{backgroundColor: option.bgColor,borderColor: option.bgColor}}>
                      <div className="option-card-content" style={{textAlign: 'center'}}>
                        <header className="">
                          <img src={option.icon} alt={option.title}/>
                          <h5 style={{marginBottom: '0',marginTop: '15px',color: option.textColor}}>{option.title}</h5>
                        </header>
                        <div className="option-excerpt">
                          <p style={{color: option.textColor,marginBottom: '0', marginTop: '15px'}}>{option.description}</p>
                        </div>
                      </div>
                    </div>
                  </article>
                  ))}
                </div>
              </div>
            </section>
        ):'';
    }
}
