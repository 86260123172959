import React from 'react';
import _ from 'lodash';

import {safePrefix, markdownify, Link} from '../utils';

export default class SectionHero extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      hover: false
    }
  }
  toggleHover = () => {
    this.setState({hover: !this.state.hover})
  }

    render() {
        const textColor = {
          color: _.get(this.props, 'section.textColor')
        }

        var background = {
          background: _.get(this.props, 'section.bg')
        }

        if(!_.isEmpty(_.get(this.props, 'section.bgGradient'))) {
          background = {
            background: 'radial-gradient(circle farthest-side at center bottom,'+_.get(this.props, 'section.bg')+','+_.get(this.props, 'section.bgGradient')+' 125%)'
          }
        }
        
        var linkStyle;
        if (this.state.hover) {
          linkStyle = {backgroundColor: _.get(this.props, 'section.btnBgColorHover'), color: _.get(this.props, 'section.btnTextColorHover')}
        } else {
          linkStyle = {backgroundColor: _.get(this.props, 'section.btnBgColor'), color: _.get(this.props, 'section.btnTextColor')}
        } 

        return _.get(this.props, 'section.published') ? (
            <section id={_.get(this.props, 'section.section_id')} className="block hero-block outer" style={{...background}}>
              <div className="inner">
                <div className="grid">
                  {_.get(this.props, 'section.image') && 
                  <div className="cell block-preview">
                    <img src={safePrefix(_.get(this.props, 'section.image'))} alt={_.get(this.props, 'section.title')} />
                  </div>
                  }
                  <div className="cell block-content">
                    {_.get(this.props, 'section.titleGroup.title') && 
                    <h2 className="block-title underline" style={{...textColor, fontSize: _.get(this.props, 'section.titleGroup.fontSize')}}>{_.get(this.props, 'section.titleGroup.title')}</h2>
                    }
                    <div className="block-copy" style={{...textColor, fontSize: _.get(this.props, 'section.contentGroup.fontSize')}}>
                      {markdownify(_.get(this.props, 'section.contentGroup.content'))}
                    </div>
                    {_.get(this.props, 'section.actions') && 
                    <p className="block-buttons" style={{...textColor}}>
                      {_.map(_.get(this.props, 'section.actions'), (action, action_idx) => (
                      <Link style={linkStyle} key={action_idx} to={safePrefix(_.get(action, 'url'))} className="button white large" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>{_.get(action, 'label')}</Link>
                      ))}
                    </p>
                    }
                  </div>
                </div>
              </div>
            </section>
        ):'';
    }
}
