import React from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import {sendData} from '../utils/SubmitToNetlifyCMS'

export default class SubscribeForm extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            email: '',
            sending: false,
            isSent: false,
            isSentOffline: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({sending: true})
        sendData("subscribeForm", {email: this.state.email}).then(res => {
            this.setState({sending: false, isSent: true})
        }).catch(error => {
            if(error instanceof TypeError && error.message === 'Failed to fetch') {
              this.setState({sending: false,isSent: true, isSentOffline: true})
            } else {
              this.setState({sending: false})
            }
        })
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value })

    render() {
        return (
          this.state.isSent ? (
            this.state.isSentOffline ? (
              <div style={{textAlign: 'center', color: '#228652',paddingTop: '10px',paddingBottom: '10px',
                background: 'white', borderRadius: '5px'}}>
                <i className="check"></i>
                <strong style={{paddingLeft: '10px'}}>Submission Queued for Sending!</strong>
            </div>
            ):(
              <div style={{textAlign: 'center', color: '#228652',paddingTop: '10px',paddingBottom: '10px',
                background: 'white', borderRadius: '5px'}}>
                <i className="check"></i>
                <strong style={{paddingLeft: '10px'}}>Successfully Subscribed!</strong>
            </div>
            )
          ):(
            <LoadingOverlay style={this.state.sending ? { backgroundColor: '#2C2F3B', opacity: 0.9 }: {}}> 
                <Loader text="Please wait ..." loading={this.state.sending}/>
            <form name="subscribeForm" method="POST" data-netlify="true" id="subscribe-form"
              className="subscribe-form"
              onSubmit={this.handleSubmit}>
              {/* <div className="screen-reader-text">
                <label>Don't fill this out if you're human: <input name="bot-field" /></label>
              </div> */}
              <div className="form-row">
                <label>
                  <span className="screen-reader-text">Email address</span>
                  <input className="subscribe-email" type="email" name="email" value={this.state.email} onChange={this.handleChange} required placeholder="Enter Email Address..."/>
                </label>
              </div>
              <input type="hidden" name="form-name" value="subscribeForm" />
              <button className="button" type="submit" style={styles.submit}>Subscribe</button>
            </form>
            </LoadingOverlay>
          )
        );
    }
}

const styles = {
  submit: {
    marginTop: '1rem',
    borderRadius: '28px',
    backgroundColor: '#fff',
    color: '#2C2F3B',
    padding: '0.7rem 1rem',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
    minWidth: '9rem',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1
  }
}