import CtaButtons from './CtaButtons';
import Footer from './Footer';
import Header from './Header';
import SectionLandingForm from './SectionLandingForm';
import SectionContent from './SectionContent';
import SectionCta from './SectionCta';
import SectionFaq from './SectionFaq';
import SectionFeatures from './SectionFeatures';
import SectionHero from './SectionHero';
import SectionPosts from './SectionPosts';
import SectionPricing from './SectionPricing';
import SectionReviews from './SectionReviews';
import Social from './Social';
import SubscribeForm from './SubscribeForm';
import Layout from './Layout';
import SectionCareers from './SectionCareers'
import CareersApplicationCompleted from './CareersApplicationCompleted'
import SectionJobOffers from './SectionJobOffers'
import SectionEstimateProject from './SectionEstimateProject'
import SectionOptions from './SectionOptions'

export {
    CtaButtons,
    Footer,
    Header,
    SectionLandingForm,
    SectionContent,
    SectionCta,
    SectionFaq,
    SectionFeatures,
    SectionHero,
    SectionPosts,
    SectionPricing,
    SectionReviews,
    Social,
    SubscribeForm,
    SectionCareers,
    Layout,
    CareersApplicationCompleted,
    SectionJobOffers,
    SectionEstimateProject,
    SectionOptions
};

export default {
    CtaButtons,
    Footer,
    Header,
    SectionLandingForm,
    SectionContent,
    SectionCta,
    SectionFaq,
    SectionFeatures,
    SectionHero,
    SectionPosts,
    SectionPricing,
    SectionReviews,
    Social,
    SubscribeForm,
    SectionCareers,
    Layout,
    CareersApplicationCompleted,
    SectionJobOffers,
    SectionEstimateProject,
    SectionOptions
};
