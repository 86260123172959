import React from 'react';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import {htmlToReact, Link} from '../utils';
import 'react-notifications/lib/notifications.css';
import AnalogClock from 'analog-clock-react';
import {sendData} from '../utils/SubmitToNetlifyCMS'

export default class SectionEstimateProject extends React.Component {

    constructor(props) {
      super(props)
      this.companyType = 'Start-up'
      this.projectType = 'Website'
      this.state = {
        formData: {
          name: '', 
          email: '',
          companyType: this.companyType,
          projectType: this.projectType,
          website: '',
          message: ''
        },
        isSending: false,
        isSent: false,
        isSentOffline: false
      }
    }

    handleSubmit = e => {
      e.preventDefault();
      this.setState({isSending: true})
      sendData("estimateProjectForm", this.state.formData).then(res => {
        var formData = this.state.formData
        Object.keys(formData).forEach(v => formData[v] = '')
        Object.assign(formData, {projectType: this.projectType, companyType: this.companyType})
        this.setState({isSending: false, formData, isSent: true})
      }).catch(error => {
        if(error instanceof TypeError && error.message === 'Failed to fetch') {
          this.setState({isSending: false,isSent: true, isSentOffline: true})
        } else {
          NotificationManager.error('Please try again')
          this.setState({isSending: false})
        }
      })
    };

    handleChange = e => this.setState({ formData: Object.assign(this.state.formData, {[e.target.name]: e.target.value}) })
    
    setProjectType = projectType => this.setState({ formData: Object.assign(this.state.formData, {projectType}) })

    render() {
        let section = _.get(this.props, 'section');
        let clockOptions = {
            width: "150px",
            border: true,
            borderColor: "#2e2e2e",
            baseColor: "#17a2b8",
            centerColor: "#459cff",
            handColors: {
              second: "#d81c7a",
              minute: "#fff",
              hour: "#fff"
            }
        };

        return _.get(this.props, 'section.published') ? (
            <section id={_.get(section, 'section_id')} className={'block contact-block outer'} style={{backgroundColor: _.get(section, 'bg')}}>
              <div className="inner">
                <div className="block-item">
                  <div className="grid" style={{alignItems: 'start'}}>
                    <div className="cell">
                        <div className="block-content inner-medium">
                          {_.get(section, 'formTitleGrp.formTitle') &&
                            <h4 style={{textAlign: 'center', color: _.get(section, 'formTitleGrp.color')}}>
                              {htmlToReact(_.get(section, 'formTitleGrp.formTitle'))}
                            </h4>
                          }
                          {this.state.isSent ? (
                            this.state.isSentOffline ? (
                              <div style={{textAlign: 'center', color: '#228652', marginTop: '60px',padding: '20px',
                                  background: 'white', borderRadius: '5px'}}>
                                <i className="check"></i>
                                <strong style={{paddingLeft: '10px'}}>Submission Queued for Sending!</strong>
                              </div>
                            ):(
                              <div style={{textAlign: 'center', color: '#228652', marginTop: '60px',padding: '20px',
                                  background: 'white', borderRadius: '5px'}}>
                                <i className="check"></i>
                                <strong style={{paddingLeft: '10px'}}>Successfully Submitted!</strong>
                              </div>
                            )
                          ):(
                            <LoadingOverlay
                              active={this.state.isSending}
                              spinner
                              text='Please wait...'
                              >
                                <form name="estimateProjectForm" method="POST" data-netlify="true"
                                  style={{...styles.form}}
                                  onSubmit={this.handleSubmit}>
                                  
                                  <input style={{...styles.input}} placeholder="Your Name *" type="text" name="name" required value={this.state.formData.name} onChange={this.handleChange}/>
                                  <input style={{...styles.input}} placeholder="Your business email *" type="email" name="email" required value={this.state.formData.email} onChange={this.handleChange}/>
                                  <div style={{position: 'relative',width: '100%'}}>
                                    <select style={{...styles.input,...styles.select}} name="companyType" value={this.state.formData.companyType} onChange={this.handleChange}>
                                      <option value="Start-up">Start-up</option>
                                      <option value="small-medium-business">Small/Medium Business</option>
                                      <option value="enterprise">Enterprise</option>
                                      <option value="other">Other</option>
                                    </select>
                                    <svg width="10" height="6" fill="none" display="block" style={{...styles.selectArrow}}><path d="M1 1l4 4 4-4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                  </div>
                                  <input style={{...styles.input}} placeholder="Your Website" type="text" name="website" value={this.state.formData.website} onChange={this.handleChange}/>
                                  <div style={{position: 'relative',display: 'flex',alignItems: 'center', marginTop: '1rem'}}>
                                    <span style={{paddingRight: '10px',fontSize: '0.8rem',fontWeight: 'bolder'}}>Select type of project: </span>
                                    <input type="hidden" name="projectType" value={this.state.formData.projectType}/>
                                    
                                    <div
                                      onClick={() => this.setProjectType('Website')} 
                                      style={this.state.formData.projectType === 'Website' ? {...styles.optionContainer, ...styles.optionSelected} : {...styles.optionContainer}}>
                                      <span>Website</span>
                                    </div>
                                    <div
                                      onClick={() => this.setProjectType('PWA')} 
                                      style={this.state.formData.projectType === 'PWA' ? {...styles.optionContainer, ...styles.optionSelected} : {...styles.optionContainer}}>
                                      <span>PWA</span>
                                    </div>
                                    <div
                                      onClick={() => this.setProjectType('eCommerce')} 
                                      style={this.state.formData.projectType === 'eCommerce' ? {...styles.optionContainer, ...styles.optionSelected} : {...styles.optionContainer}}>
                                      <span>eCommerce</span>
                                    </div>
                                    <div 
                                      onClick={() => this.setProjectType('Other')} 
                                      style={this.state.formData.projectType === 'Other' ? {...styles.optionContainer, ...styles.optionSelected} : {...styles.optionContainer}}>
                                      <span>Other</span>
                                    </div>
                                  </div>
                                  <textarea style={{...styles.input}} placeholder="How can we help you?" rows="4" name="message" value={this.state.formData.message} onChange={this.handleChange}></textarea>

                                  <button style={{...styles.submit}} type="submit">Request Free Consultation Now!</button>
                                </form>
                            </LoadingOverlay>
                          )}
                        </div>
                    </div>
                    <div className="cell">
                        <div>
                          {_.get(section, 'titleGrp.title') &&
                          <h1 className="block-" style={{fontSize: '2.8rem',marginBottom: '1.5rem',color: _.get(section, 'titleGrp.color')}}>{_.get(section, 'titleGrp.title')}</h1>
                          }
                          {_.get(section, 'subtitleGrp.subtitle') &&
                          <h3 style={{fontSize: '4rem', marginTop: '1rem', marginBottom: '2rem', color: _.get(section, 'subtitleGrp.color')}}>
                            {htmlToReact(_.get(section, 'subtitleGrp.subtitle'))}
                          </h3>
                          }
                        </div>

                        <div style={{backgroundColor: '#2C2F3B', color: 'white', display: 'flex',flexDirection: 'row', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                            <div style={{width: '50%'}}>
                                <b>Contact us</b>
                                <small style={{display: 'block'}}>Prepare for a quick response <br/>
                                <a style={{color: '#ED1C24'}} href="mailto:contact@accelerone.com">contact@accelerone.com</a></small>

                                <small style={{display: 'block', paddingTop: '35px'}}>Indianapolis</small>
                                <small style={{display: 'inline-flex', textAlign:'center', paddingTop: '0px'}}>
                                  <a href="#">Get directions</a>
                                  <span style={{color: '#4c5267', width:'10px'}}>|</span>
                                  <a href="tel:+1 317 993 5506">+1 317 993 5506</a>
                                </small>
                            </div>
                            <div style={{width: '50%', color: '#8B8C8D', textAlign: 'center'}}>
                                <b>Careers</b>
                                <small style={{display: 'block'}}>Let's work together <br/>
                                <Link to="/careers">Jobs at Accelerone</Link></small>
                                <div style={{width: '100%'}} id="clock-container">
                                <AnalogClock {...clockOptions}/>
                                </div>
                            </div>
                        </div>
                    </div>


                  </div>
                </div>
              </div>
              
              
              <NotificationContainer />
            </section>
        ):'';
    }
}

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '30.300000000000004rem',
    margin: '2.1rem auto 6.2rem'
  },
  input: {
    padding: '0.8rem 1.22rem',
    marginTop: '1rem',
    boxShadow: 'none',
    border: '2px solid #f5f9fb',
    color: '#000',
    lineHeight: 'normal',
    fontWeight: '600'
  },
  select: {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      width: '100%'
  },
  selectArrow: {
    position: 'absolute',
    right: '1.22rem',
    top: '2.3rem',
    pointerEvents: 'none'
  },
  optionSelected: {
    backgroundColor: '#2C2F3B',
    color: '#fff',
  },
  optionContainer: {
    marginRight: '15px',
    borderRadius: '15px',
    backgroundColor: '#f5f9fb',
    color: 'black',
    padding: '1.0333333333333332rem 2.0666666666666664rem',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
    // minWidth: '9rem',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    padding: '.6200000000000001rem 1.0333333333333332rem',
    fontSize: '.875rem',
    // minWidth: 0,
    '::before': {
      content: "",
      backgroundColor: '#2C2F3B',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      transition: 'all .3s cubic-bezier(0,.89,.44,1)',
      transform: 'translateZ(0) scale(.9999)',
      zIndex: -1,
      borderRadius: '28px',
      transformStyle: 'preserve-3d',
      webkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden'
    }
  },
  file: {
    position: 'absolute',
    left: 0,
    height: '100%',
    zIndex: 2,
    opacity: 0,
    top: 0,
    cursor: 'pointer',
    width: '100%',
    WebkitAppearance: 'initial',
    backgroundColor: 'initial',
    cursor: 'default',
    alignItems: 'baseline',
    color: 'inherit',
    textAlign: 'start !important',
    padding: 'initial',
    border: 'initial'
  },
  submit: {
    marginTop: '1rem',
    borderRadius: '28px',
    backgroundColor: '#2C2F3B',
    color: '#fff',
    padding: '1.0333333333333332rem 2.0666666666666664rem',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
    minWidth: '9rem',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1
  }
}
