import React, {Component} from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

export default class CareersApplicationCompleted extends Component {

    render() {
        return (
            <div style={{textAlign: 'center', paddingTop: '50px',paddingBottom: '50px'}}>
                <StaticQuery 
                    query={query}
                    render={({file}) => (
                        <Img fixed={file.childImageSharp.fixed} alt="completed"/>
                    )}/>

                <h4>Your Application has been Successfully Submitted</h4>

                <Link to={'/'} className="button" style={{marginTop: '50px'}}>Return Home</Link>
            </div>
        )
    }
}

const query=graphql`
  {
    file(base: {eq: "completed.png"}) {
        childImageSharp {
        fixed(width: 150) {
            ...GatsbyImageSharpFixed
        }
        }
    }
  }      
`