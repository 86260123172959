module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1132},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-89247313-3","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NVJ3XMR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Accelerone","short_name":"Accelerone","description":"Accelerone is an agency that promotes innovation by helping retailers with powerful eCommerce solutions","lang":"en","start_url":"/","background_color":"#386F9C","theme_color":"#a2466c","display":"standalone","icon":"static/images/accelerone-s.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/services/","/about-1/","/news/","/careers/","/contact"],"workboxConfig":{"importWorkboxFrom":"cdn"},"appendScript":"/opt/build/repo/static/sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
