import React from 'react';
import _ from 'lodash';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import LoadingOverlay from 'react-loading-overlay';
import {sendData} from '../utils/SubmitToNetlifyCMS'
import {htmlToReact, markdownify} from '../utils';
import 'react-notifications/lib/notifications.css';

export default class SectionLandingForm extends React.Component {

    constructor(props) {
      super(props)
      
      this.state = {
        formData: {
          firstname: '',
          lastname: '',
          email: '',
          company: '',
          formCode: _.get(props, 'section.landingFormCode')
        },
        isSending: false,
        isSent: false,
        isSentOffline: false
      }
    }

    handleSubmit = e => {
      e.preventDefault();
      this.setState({isSending: true})
      sendData("landingForm", this.state.formData).then(res => {
        this.setState({isSending: false, isSent: true})
      }).catch(error => {
        if(error instanceof TypeError && error.message === 'Failed to fetch') {
          this.setState({isSending: false,isSent: true, isSentOffline: true})
        } else {
          NotificationManager.error('Please try again')
          this.setState({isSending: false})
        }
      })
    };

        handleChange = e => this.setState({ formData: Object.assign(this.state.formData, {[e.target.name]: e.target.value}) })

    render() {
        let section = _.get(this.props, 'section');
        const bgColor = _.get(section, 'bgColor') ? _.get(section, 'bgColor'): 'white'
        const textColor = _.get(section, 'textColor') 
        return _.get(this.props, 'section.published') ?  (
            <section id={_.get(section, 'section_id')} className={'block hero-block outer'} style={{
                background: bgColor,
                backgroundImage: 'url('+_.get(section, 'bg')+')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top'}}>
              <NotificationContainer />
              <div className="inner">
              <div className="grid" style={{alignItems: 'start',flexDirection: 'initial', paddingTop: '45px'}}>
                <div className="cell" style={{color: textColor}}>
                  {_.get(section, 'title') &&
                  <h2 style={{fontSize: '2.5rem', color: textColor, marginBottom: '40px',fontFamily: 'Arial, sans-serif', fontWeight: 400, fontStyle: 'normal'}}><strong>{_.get(section, 'title')}</strong></h2>
                  }
                  {markdownify(_.get(section, 'content'))}
                  {_.get(section, 'ebookImg') &&
                    <div style={{width: '100%', textAlign: 'center'}}>
                      <img style={{width: '200px'}} src={_.get(section, 'ebookImg')} alt="ebook"/>
                    </div>
                  }
                </div>
                <div className="cell" style={{backgroundColor: textColor, padding:'30px',textAlign: 'left',boxShadow: '#eee 0 0 8px 1px'}}>
                  {_.get(section, 'formTitle') &&
                    <h3 style={{marginBottom: '30px', fontWeight: 400, fontStyle: 'normal'}}>
                      {htmlToReact(_.get(section, 'formTitle'))}
                    </h3>
                  }

                {this.state.isSent ? (
                  this.state.isSentOffline ? (
                    <div style={{textAlign: 'center', color: '#228652', marginTop: '60px',padding: '20px',
                      marginBottom: '40px'}}>
                      <i className="check"></i>
                      <strong style={{paddingLeft: '10px'}}>Submission Queued for Sending!</strong>
                    </div>
                  ):(
                    <div style={{textAlign: 'center', color: '#228652', marginTop: '60px',padding: '20px',
                      marginBottom: '40px'}}>
                      <i className="check"></i>
                      <strong style={{paddingLeft: '10px'}}>Successfully Submitted!</strong>
                  </div>
                  )
                ):(
                  <LoadingOverlay
                    active={this.state.isSending}
                    spinner
                    text='Please wait...'
                    >
                      <form name="landingForm" method="POST" data-netlify="true" id="contact-form"
                        className="contact-form"
                        onSubmit={this.handleSubmit}>
                        <p className="screen-reader-text">
                          <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <p className="form-row">
                          <input type="text" name="firstname" style={styles.input} className="form-input" required value={this.state.formData.firstname} onChange={this.handleChange} placeholder="First Name"/>
                        </p>
                        <p className="form-row">
                          <input type="text" name="lastname" style={styles.input} className="form-input" value={this.state.formData.lastname} onChange={this.handleChange} placeholder="Last Name"/>
                        </p>
                        <p className="form-row">
                          <input type="email" name="email" style={styles.input} className="form-input" required value={this.state.formData.email} onChange={this.handleChange} placeholder="Email address"/>
                        </p>
                        <p className="form-row">
                          <input type="text" name="company" style={styles.input} className="form-input" value={this.state.formData.company} onChange={this.handleChange} placeholder="Company Name"/>
                        </p>
                        <input type="hidden" name="form-name" value={_.get(section, 'landingFormCode')} />
                        <p className="form-row form-submit">
                          <button type="submit" style={styles.submit} className="button">GET FREE EBOOK</button>
                        </p>
                      </form>
                    </LoadingOverlay>
                  )}
                </div>
              </div>
              </div>
            </section>
        ):'';
    }
}


const styles = {
  input: {
    padding: '0.8rem 1.22rem',
    boxShadow: 'none',
    border: '2px solid #f5f9fb',
    color: '#000',
    lineHeight: 'normal',
    fontWeight: '600'
  },
  submit: {
    marginTop: '1rem',
    borderRadius: '28px',
    backgroundColor: '#2C2F3B',
    color: '#fff',
    padding: '0.7rem 1.2rem',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
    minWidth: '9rem',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1
  }
}