const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k)=>{
      formData.append(k,data[k])
    });
    return formData
}

export const sendData = (formName, formData, method="POST") => {
    return fetch("/", {
      method,
      // headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": formName, ...formData })
    })
}