import React from 'react';
import _ from 'lodash';
import {navigate} from 'gatsby'
import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import {htmlToReact} from '../utils';
import {sendData} from '../utils/SubmitToNetlifyCMS'
import 'react-notifications/lib/notifications.css';


export default class SectionCareers extends React.Component {

    constructor(props) {
      super(props)
      
      this.successPage = _.get(props.section, 'successPage') 
      this.state = {
        formData: {
          role: this.props.section.role,
          firstName: '', 
          lastName: '',
          email: '', 
          github: '',
          behance: '',
          social: '', 
          resume: null,
          message: ''
        },
        isSending: false
      }
    }

    formSubmitted = (msg) => {
      if(this.successPage === false) {
        NotificationManager.success(msg)
        var formData = this.state.formData
        Object.keys(formData).forEach(v => formData[v] = '')
        Object.assign(formData, {resume: null})
        this.setState({isSending: false, formData})
      } else {
        navigate('/careers/application-completed')
      }
    }

    submitForm = () => {
      var errors = null
      const {resume} = this.state.formData
      // check file size
      if(resume === null) {
         errors = "Please attach your resume."
      }

      if(errors === null) {
        sendData("careersForm", this.state.formData).then(res => {
          this.formSubmitted('Application submited!')
        }).catch(error => {
          if(error instanceof TypeError && error.message === 'Failed to fetch') {
            this.formSubmitted('Submission Queued for Sending!')
          } else {
            NotificationManager.error('Please try again')
            this.setState({isSending: false})
          }
        })
      } else {
        this.setState({isSending: false})
        NotificationManager.error(errors)
      }
    }

    handleSubmit = e => {
      e.preventDefault();
      this.setState({isSending: true})
      this.submitForm()
    };

    handleChange = e => this.setState({ formData: Object.assign(this.state.formData, {[e.target.name]: e.target.value}) })

    handleResumeChange = e => {
      const files = e.target.files

      var FileSize = files[0].size / 1024 / 1024
      if (FileSize > 5) {
        NotificationManager.error("File is too large. Max upload size is 5mb.")
      } else {
        this.setState({formData: Object.assign(this.state.formData, {resume: files[0]})})
      }
    }

    handleResumeClean = e => {
      this.setState({formData: Object.assign(this.state.formData, {resume: null})})
    }
    
    render() {
      console.log(this.props.section.role)
        let section = _.get(this.props, 'section');
        return _.get(this.props, 'section.published') ? (
            <section id={_.get(section, 'section_id')} className={'block contact-block bg-' + _.get(section, 'bg') + ' outer'}>
              <NotificationContainer />
              <div className="block-header inner-small">
                {_.get(section, 'title') &&
                <h2 className="block-title">{_.get(section, 'title')}</h2>
                }
                {_.get(section, 'subtitle') &&
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle'))}
                </p>
                }
              </div>
              <div className="block-content inner-medium">
                
              <LoadingOverlay
                active={this.state.isSending}
                spinner
                text='Please wait...'
                >
                  <form name="careersForm" method="POST" data-netlify="true"
                    style={{...styles.form}}
                    onSubmit={this.handleSubmit}>
                
                    <input type="hidden" name="role" value={_.get(section, 'role')} />
                    <input style={{...styles.input}} placeholder="First name *" type="text" name="firstName" required value={this.state.formData.firstName} onChange={this.handleChange}/>
                    <input style={{...styles.input}} placeholder="Last name *" type="text" name="lastName" required value={this.state.formData.lastName} onChange={this.handleChange}/>
                    <input style={{...styles.input}} placeholder="Contact email address *" type="email" name="email" required value={this.state.formData.email} onChange={this.handleChange}/>
                    {_.get(section, 'github') === true && (
                      <input style={{...styles.input}} placeholder="Link to your GitHub profile (optional)" type="text" name="github" value={this.state.formData.github} onChange={this.handleChange}/>
                    )}
                    {_.get(section, 'behance') === true && (
                      <input style={{...styles.input}} placeholder="Link to your Behance or Dribbble profile (optional)" type="text" name="behance" value={this.state.formData.behance} onChange={this.handleChange}/>
                    )}
                    <input style={{...styles.input}} placeholder="Link to your LinkedIn or Twitter profile (optional)" type="text" name="social" value={this.state.formData.social} onChange={this.handleChange}/>
                    <div style={{position: 'relative',display: 'flex',alignItems: 'center'}}>
                      {this.state.formData.resume === null && (
                        <div style={{...styles.uploadContainer}}>
                          <span>Upload resume</span><input type="file" name="resume" id="resume" style={{...styles.file}} accept=".pdf" onChange={this.handleResumeChange}/>
                        </div>
                      )}
                      {this.state.formData.resume !== null && this.state.formData.resume.name !== undefined && (
                        <>
                          <div style={{...styles.uploadContainer}} onClick={this.handleResumeClean}>
                            <span>Clean</span>
                          </div>
                          <small style={{paddingLeft: '10px'}}>{this.state.formData.resume.name}</small>
                        </>
                      )}
                    </div>
                    <textarea style={{...styles.input}} placeholder="Why Accelerone?" rows="4" name="message" value={this.state.formData.message} onChange={this.handleChange}></textarea>

                    <button style={{...styles.submit}} type="submit">Submit Application!</button>
                  </form>
                </LoadingOverlay>
              </div>
            </section>
        ):'';
    }
}

const styles = {
  form: {
    display: 'grid',
    rowGap: '1.55rem',
    maxWidth: '40.300000000000004rem',
    margin: '3.1rem auto 6.2rem'
  },
  input: {
    borderRadius: '28px',
    background: '#f5f9fb',
    padding: '1.0333333333333332rem 1.55rem',
    boxShadow: 'none',
    border: '2px solid #f5f9fb',
    color: '#000',
    lineHeight: 'normal',
    fontWeight: '600'
  },
  select: {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      width: '100%'
  },
  selectArrow: {
    position: 'absolute',
    right: '1.55rem',
    top: '27px',
    pointerEvents: 'none'
  },
  uploadContainer: {
    borderRadius: '28px',
    backgroundColor: '#da3654',
    color: '#fff',
    padding: '1.0333333333333332rem 2.0666666666666664rem',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
    minWidth: '9rem',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    padding: '.6200000000000001rem 1.0333333333333332rem',
    fontSize: '.875rem',
    minWidth: 0,
    '::before': {
      content: "",
      backgroundColor: '#da3654',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      transition: 'all .3s cubic-bezier(0,.89,.44,1)',
      transform: 'translateZ(0) scale(.9999)',
      zIndex: -1,
      borderRadius: '28px',
      transformStyle: 'preserve-3d',
      webkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden'
    }
  },
  file: {
    position: 'absolute',
    left: 0,
    height: '100%',
    zIndex: 2,
    opacity: 0,
    top: 0,
    cursor: 'pointer',
    width: '100%',
    WebkitAppearance: 'initial',
    backgroundColor: 'initial',
    cursor: 'default',
    alignItems: 'baseline',
    color: 'inherit',
    textAlign: 'start !important',
    padding: 'initial',
    border: 'initial'
  },
  submit: {
    borderRadius: '28px',
    backgroundColor: '#da3654',
    color: '#fff',
    padding: '1.0333333333333332rem 2.0666666666666664rem',
    WebkitTextDecoration: 'none',
    textDecoration: 'none',
    minWidth: '9rem',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1
  }
}